/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div"
  }, _provideComponents(), props.components), {TableOfContents, StateCta} = _components;
  if (!StateCta) _missingMdxReference("StateCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing loss can be experienced by anyone in North Carolina, and it’s something that millions of state residents have to deal with. This article explains the major hearing risks in the Tar Heel State and offers practical advice on what to do when your hearing starts to fail."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "ways-to-damage-your-hearing-in-north-carolina",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#ways-to-damage-your-hearing-in-north-carolina",
    "aria-label": "ways to damage your hearing in north carolina permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Ways to damage your hearing in North Carolina"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There's no shortage of ways to damage your ears and auditory system. Take Nascar, for example. Hundreds of thousands of fans flock to the Charlotte Motor Speedway to watch national competitions every year. And with the high-speed vehicles – capable of 100-140 decibels – competing against each other, Nascar is virtually an assault on your auditory system."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The same goes for hunting and shooting - another great North Carolina tradition. Studies have shown that men over the age of 45 who hunt regularly are much more likely to develop severe hearing loss. It’s not a surprise that 95 percent of hunters admit to not wearing adequate hearing protection. But it's a shame for hunters who need to be able to hear their prey and colleagues to get the most out of their recreation. But hearing risks go beyond hunting and Nascar."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are North Carolina's highways, the screaming fans at the Bank of America Stadium when the Panthers are in town, as well as major events like the Rebellion Rock festival every May. What's clear to audiologists and hearing specialists in North Carolina is that hearing loss is on the rise."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-loss-is-a-problem-in-north-carolina",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-loss-is-a-problem-in-north-carolina",
    "aria-label": "hearing loss is a problem in north carolina permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing loss is a problem in North Carolina"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Let's take a look at the statistics to illustrate the issue more clearly. In the USA, around 20 percent of adults report some degree of hearing loss. Given North Carolina's population of approximately 10 million, you can bet that many of Tar Heelers are currently struggling to hear clearly."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "And the numbers are higher the older you get. By 65, over a third of Americans suffer from hearing loss, while a quarter of those aged over 70 has \"disabling\" levels of hearing loss. In North Carolina, that's a growing problem. As the N.C. Study Commission on Aging puts it, the number of North Carolinian seniors with severe hearing loss is expected to double in the next 20 years as baby boomers grow older."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "However, all across America (and NC is no exception), people who struggle to hear are slow to seek assistance. Even among seniors, around 30 percent of those who can benefit from hearing aids has ever used them, and the figure drops to just 16 percent for those aged between 18 and 65. That's where hear.com can be of assistance."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-hearcom-helps",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-hearcom-helps",
    "aria-label": "how hearcom helps permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How hear.com helps"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At hear.com, we're all about helping North Carolinians improve their quality of life. If you're an active senior and want to restore clarity to your cafe conversations, or you're a professional who needs to catch every word in corporate meetings, we can help. Our team works with a network of over 80 Partner Providers all over the state, including audiologists in every major NC city."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Our team of experts is always available to start the process of finding the perfect hearing aid. Whether you're in Raleigh, Charlotte or Greensboro, hear.com makes it as easy as possible to connect with knowlegable professionals who can solve your hearing issues."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Modern hearing aids can make a huge difference in your quality of life, without compromising your comfort or appearance. Actually, the reality is exactly the opposite. As you'll discover when you talk to our experts, today's hearing tech is more advanced than most people realize."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "contact-hearcom-to-turn-your-hearing-around",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#contact-hearcom-to-turn-your-hearing-around",
    "aria-label": "contact hearcom to turn your hearing around permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Contact hear.com to turn your hearing around"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you live anywhere in North Carolina and are experiencing mild, moderate or severe hearing loss symptoms, it's time to take action. The sooner you do so, the more effective the response will be, so don't delay!"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The first step is to fill out our online questionnaire, which requires a few simple pieces of information about your hearing issues. In no time, we'll contact you to arrange an appointment to check your hearing with one of our local Partner Providers."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The whole process is fast, hassle-free and easy, so get in touch below:"), "\n", React.createElement(StateCta, {
    backgroundColor: "white"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
